<template>
  <div v-if="dialog" class="relative z-50">
    <div class="fixed inset-0 bg-black bg-opacity-80"></div>
    <div class="fixed inset-0 overflow-auto">
      <div class="flex items-center justify-center h-full">
        <div class="bg-white rounded-lg p-4 w-full max-w-lg m-2">
          <div class="flex flex-col gap-2">
            <div class="text-right cursor-pointer text-lg" @click="cancel">
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </div>
            <img :src="data.foto" :alt="data.judul" class="" />
            <div class="text-lg text-center font-bold text-primary">
              {{ data.judul }}
            </div>
            <div class="flex flex-row justify-between">
              <div class="flex flex-row">
                <span
                  ><font-awesome-icon
                    icon="fa-regular fa-clock"
                    class="text-gray-400 mr-2 text-lg"
                /></span>
                <div class="flex flex-col">
                  <span class="text-gray-400 text-sm">Valid until</span>
                  <span class="text-primary font-bold text-sm">{{
                    this.$moment(data.waktu_selesai, "DD/MM/YYYY").format(
                      "DD MMMM YYYY"
                    )
                  }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <span
                  ><font-awesome-icon
                    icon="fa-regular fa-clock"
                    class="text-gray-400 mr-2 text-lg"
                /></span>
                <div class="flex flex-col">
                  <span class="text-gray-400 text-sm">Min. Transaction</span>
                  <span class="text-primary font-bold text-sm">{{
                    data.minimal | toCurrency
                  }}</span>
                </div>
              </div>
              <div class="flex">
                <button
                  type="button"
                  class="rounded-lg bg-primary text-white font-bold w-24"
                  @click="use"
                >
                  Use
                </button>
              </div>
            </div>
            <div class="flex w-full" v-html="data.deskripsi"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      data: {},
    };
  },
  async created() {
  },
  methods: {
    open({ data }) {
      this.data = data ?? {};
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    use() {
      this.resolvePromise(true);
      this.dialog = false;
    },
    cancel() {
      this.resolvePromise(false);
      this.dialog = false;
    },
  },
};
</script>
